@import '~shared-design/styles/variables';

.Link {
  display: inline-flex;
  text-decoration: none;

  .SecondaryText {

    &:hover {
      color: $highlight-font-color-hover;
    }
  }
}