@import "~shared-design/styles/variables";

.DatePicker,
.DateTimePicker {
  display: flex;
  align-items: center;
}

.DateButton {
  display: flex;
  flex-direction: row;

  align-items: center;

  &__icon {
    margin-right: 0.5rem;
    color: $secondary-yellow;
  }

  .text-button {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 0.2rem;

    &:hover:not([disabled])::after {
      background: $secondary-yellow;
      transition: all 0.2s ease-in;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
      transition: all 0.2s ease-in;
    }

    &[disabled]::after {
      background: $main-font-color-extra-muted;
    }
  }
}
