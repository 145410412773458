@import '~shared-design/styles/variables';

.Dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &__success {
    background-color: $secondary-light-green;
  }

  &__warning {
    background-color: $secondary-yellow;
  }

  &__error {
    background-color: $secondary-red;
  }

  &__orange {
    background-color: $secondary-orange;
  }

  &__grey {
    background-color: $element-grey;
  }

  &__blue {
    background-color: $secondary-blue;
  }

  &__purple {
    background-color: mediumpurple;
  }
}

.Dots {
  &__animated {
    .Dot {
      border: 1px solid $canonized-gray-bg-medium;
      height: 12px;
      width: 12px;

      &:hover:not(:active) {
        transform: translateY(-2px);
        transition: transform 0.3s ease-in-out;
      }

      &:not(:hover):not(:active) {
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
      }
    }
  }
}
