@import "~shared-design/styles/variables";

.TimePicker {
  &__input {
    display: flex;
    height: 2.75rem;
    background: #39444b;
    border-radius: 6px;
    align-self: center;
    align-items: center;

    padding: 0 0.5rem 0 0.5rem;

    min-width: 4rem;

    // margin-left: 0.5rem;

    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: $screen-medium) {
      min-width: 6rem;
    }
  }
}
