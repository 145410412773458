@import '~shared-design/styles/variables';

.SpaceDetails {
  font-family: 'Barlow';
  margin: auto;
  max-width: 900px;

  display: flex;
  flex-direction: column;

  font-family: 'Barlow';

  &__separator {
    width: 100%;
    height: 1px;
    border: none !important;
    background: #39444b;
    margin: 1rem 0rem 1rem 0rem;
  }

  &__icon {
    color: $secondary-yellow;
    margin-right: 1rem;
  }

  &__section {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #39444b;
  }

  &__section-heading {
    margin-top: 0rem;
    display: flex;
    align-items: center;
  }

  &__buttons {
    margin-top: 3rem;
    margin-bottom: 2rem;
    justify-content: space-between;
  }

  &__timerange-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__timerange {
    font-weight: 600;
    white-space: nowrap;
  }

  &__timerange-message {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem 0;

    &::before {
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='-1 -1 2 2'><g> <rect fill='%23f7b83a' x='0' y='0' width='1' height='1' rx='0.5'></rect> </g></svg>");
      margin-right: 1rem;
    }

    &--other-space {
      font-weight: 600;
      margin-left: 0.5rem;
    }
  }

  &__ErrorMessages {
    max-width: 50%;
  }
}

.HealthCommitment {
  display: flex;
  flex-direction: column;
  position: relative;

  color: #fff;
  border-radius: 5px;
  border: 3px solid $secondary-turquoise;
  padding: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  &__fade-out {
    position: relative;
    animation: fade-out 0.5s;
  }

  @keyframes fade-out {
    100% {
      opacity: 0;
    }
    100% {
      max-height: 0;
    }
  }

  &__title {
    margin: 0.8rem 0rem 0.8rem 0rem;
    font-weight: 600;
    font-size: 1.1rem;
  }

  &__content {
    margin: 0;
    white-space: pre-line;
  }

  &__checks {
    padding-left: 0;
    list-style: inside;
  }

  &__attention {
    position: absolute;
    box-sizing: border-box;
    width: 32px !important;
    height: 32px !important;
    top: -16px;
    left: -17px;
    color: $secondary-turquoise;
    z-index: 10;
  }

  &__attention-bg {
    position: absolute;
    width: 28px !important;
    height: 28px !important;
    top: -16px;
    left: -17px;
    background: $canonized-gray-bg-primary;
    z-index: 1;
  }

  &__info {
    position: absolute;
    top: -2.4rem;
    left: 3rem;
    font-size: 1.7rem;
    font-weight: 500;
    color: $secondary-turquoise;
  }

  &__title {
    color: $secondary-yellow;
  }

  &__ok {
    display: flex;

    .text-button {
      margin-left: auto;
      font-size: 0.9rem;
      letter-spacing: 0.05rem;

      &:not(:hover) {
        color: $secondary-turquoise;
      }
    }
  }
}
