@import '~shared-design/styles/variables';

.GuestCheckIn {
  &__error {
    color: $secondary-red;
    margin: 0;

    justify-content: center;
    font-weight: bold;
  }
  &__invalid {
    color: $secondary-red;
    margin: 0;
    font-size: 20px;
    justify-content: center;
  }
  &__arriving_at {
    margin-bottom: 20px;
    .address {
      display: flex;
      align-items: center;
      font-weight: bold;
      svg {
        margin-right: 4px;
      }
      margin-bottom: 10px;
    }
    .location {
      max-width: 300px;
      background-color: $canonized-gray-bg-light;
      padding: 10px;
    }
  }
  &__Content {
    display: flex;
    align-content: center;
    justify-content: center;
   .Flex__Content {
      flex-direction: row;
      max-width: 33rem;
    }
  }
}
