@font-face {
  font-family: 'Barlow';
  font-weight: 100;
  font-style: normal;
  src: url('../assets/fonts/Barlow-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 100;
  font-style: italic;
  src: url('../assets/fonts/Barlow-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 200;
  font-style: normal;
  src: url('../assets/fonts/Barlow-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 200;
  font-style: italic;
  src: url('../assets/fonts/Barlow-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/Barlow-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 300;
  font-style: italic;
  src: url('../assets/fonts/Barlow-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Barlow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/Barlow-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Barlow-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  font-style: italic;
  src: url('../assets/fonts/Barlow-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/Barlow-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  font-style: italic;
  src: url('../assets/fonts/Barlow-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Barlow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  font-style: italic;
  src: url('../assets/fonts/Barlow-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 800;
  font-style: normal;
  src: url('../assets/fonts/Barlow-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 800;
  font-style: italic;
  src: url('../assets/fonts/Barlow-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 900;
  font-style: normal;
  src: url('../assets/fonts/Barlow-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-weight: 900;
  font-style: italic;
  src: url('../assets/fonts/Barlow-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Overpass Mono';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/OverpassMono-Regular.ttf') format('truetype');
}
