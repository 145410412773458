@import "~shared-design/styles/variables";

.DateTimePicker {
  &__input {
    display: flex;
    height: 2.75rem;
    background: #39444b;
    border-radius: 6px;
    align-self: center;
    align-items: center;

    padding: 0 1rem 0 1rem;

    min-width: 8rem;

    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: $screen-medium) {
      min-width: 6rem;
    }
  }
}

.DateButton {
  display: flex;
  flex-direction: row;

  align-items: center;

  &__icon {
    margin-right: 0.5rem;
    color: $secondary-yellow;
  }

  .text-button {
    display: flex;
    flex-direction: row;
  }
}
