@import '~shared-design/styles/variables';

.header {
  background-color: $canonized-gray-bg-dark;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;

  @media (min-width: $screen-medium) {
    & {
      grid-template: 1fr / 180px 1fr;
      justify-content: end;
      margin-bottom: 2rem;
    }
  }

  .header__logo {
    display: flex;
    justify-items: center;
    justify-content: center;
    width: 150px;
    padding: 5px 0;

    @media (min-width: $screen-medium) {
      & {
        justify-content: flex-start;
        margin-bottom: 0;
      }
    }
  }

  .header__links {
    $itemMarginLeft: 0.5em;
    display: flex;
    align-items: center;

    > a,
    > button {
      display: inline-block;
      margin-left: $itemMarginLeft;

      &:first-child {
        margin-left: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }

    .LocaleSelector {
      margin-left: $itemMarginLeft;
      transform: scale(0.8);
      z-index: 1;
    }

    @media (min-width: $screen-medium) {
      & {
        $itemMarginLeft: 2.5em;
        justify-content: flex-end;
        margin-left: auto;

        > a,
        > button,
        .LocaleSelector {
          margin: 0;
          margin-left: $itemMarginLeft;
        }
      }
    }
  }
}
