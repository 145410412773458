.SpacesList {
  &__no-available-spaces {
    display: flex;
    margin: auto;
    justify-content: center;
  }

  &__LocationTitle {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }
}
