@import "~shared-design/styles/variables";

.ReservedSpace {
  display: inline-flex;
  font-family: $main-font;
  font-weight: 600;
  margin-bottom: 2rem;

  &__space {
    color: $secondary-yellow;
    margin-left: 0.5rem;
  }
}