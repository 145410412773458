$primary-light: rgb(242, 240, 240); // #f2f0f0
$primary-dark: rgb(25, 30, 33); // #191e21

$secondary-dark-green: rgb(0, 105, 45); // #00692d
$secondary-green: rgb(3, 140, 62); // ##038c3e
$secondary-light-green: rgb(26, 179, 91); // #1ab35b
$secondary-jade-green: rgb(99, 210, 148); // #63d294
$secondary-dark-blue: rgb(48, 37, 138); // #63d294
$secondary-blue: rgb(58, 62, 199); // #3a3ec7
$secondary-light-blue: rgb(53, 132, 222); // #3584de
$secondary-turquoise: rgb(42, 175, 189); // #2aafbd
$secondary-dark-red: rgb(185, 17, 15); // #b9110f
$secondary-red: rgb(224, 42, 14); // #e02a0e
$secondary-orange: rgb(236, 108, 37); // #ec6c25
$secondary-yellow: rgb(247, 184, 58); // #f7b83a

$canonized-gray-bg-lighter: rgb(57, 68, 75); // #39444b
$canonized-gray-bg-light: rgb(38, 47, 54); // #262f36
$canonized-gray-bg-primary: $primary-dark; // #191e21
$canonized-gray-bg-medium: rgb(21, 26, 29); // #151a1d
$canonized-gray-bg-dark: rgb(18, 22, 24); // #121618

$element-grey: rgb(93, 100, 103); //#5D6467;

:export {
  primaryLight: $primary-light;
  primaryDark: $primary-dark;
  secondaryDarkGreen: $secondary-dark-green;
  secondaryGreen: $secondary-green;
  secondaryLightGreen: $secondary-light-green;
  secondaryJadeGreen: $secondary-jade-green;
  secondaryDarkBlue: $secondary-dark-blue;
  secondaryBlue: $secondary-blue;
  secondaryLightBlue: $secondary-light-blue;
  secondaryTurquoise: $secondary-turquoise;
  secondaryDarkRed: $secondary-dark-red;
  secondaryRed: $secondary-red;
  secondaryOrange: $secondary-orange;
  secondaryYellow: $secondary-yellow;
  canonizedGrayBgLighter: $canonized-gray-bg-lighter;
  canonizedGrayBgLight: $canonized-gray-bg-light;
  canonizedGrayBgPrimary: $canonized-gray-bg-primary;
  canonizedGrayBgMedium: $canonized-gray-bg-medium;
  canonizedGrayBgDark: $canonized-gray-bg-dark;
  elementGrey: $element-grey;
}
