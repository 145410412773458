.AppStoreLinks {
  display: flex;
  flex-direction: row;

  margin-top: 2rem;

  > div {
    display: flex;
    flex-direction: column;

    margin-right: 2rem;

    > span {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      margin-bottom: 0.5rem;
    }

    img {
      width: 120px;
      max-width: 156px;
    }
  }
}
