@import "~shared-design/styles/variables";

.content-container {
  width: 100%;

  .content-container__content {
    padding: 0 20px;
    max-width: 1300px;
    margin: 0 auto;

    @media (min-width: $screen-small) {
      & {
        padding: 0 45px;
      }
    }
  }
}
