@import '~shared-design/styles/variables';

a.Spacent__ALink {
  color: $secondary-yellow;
  text-decoration: none;
  transition: color 0.2s;

  &:visited, &:focus {
    color: $secondary-orange;
  }

  &:hover {
    color: $highlight-font-color-hover;
  }
}