@import "~shared-design/styles/variables";

.button {
  align-self: center;
  background-color: $main-button-color;
  color: $main-font-color;
  font-family: $main-font;
  font-size: 17px;
  font-weight: 600;
  width: 45%;
  border-radius: 6px;
  border: none;
  padding: 12px 0 13px 0;
  transition: all ease 0.3s;
  cursor: pointer;

  &.small {
    text-align: left;
    padding: 10px 18px;
    font-size: 14px;
    line-height: 22px;
    // Note: this overrides the "normal" 45 % width set in the main rule.
    // Any block level component should always take the full width available to it in order to avoid
    // conflicting rules.
    width: 100%;
  }

  &:hover:not(:disabled) {
    background-color: $main-button-hover;
  }

  &:disabled {
    pointer-events: none;
    cursor: auto;
    opacity: 0.3;
  }

  &.primary {
    background-color: $action-button-color;

    &:hover:not(:disabled) {
      background-color: $action-button-hover;
    }
  }

  &.delete {
    background-color: $danger-button-color;

    &:hover:not(:disabled) {
      background-color: $danger-button-hover;
    }
  }
}
