@import '~shared-design/styles/variables';

.PlanSelectorButton {
  display: inline-flex;
  flex-direction: column;

  .primary {
    background: rgb(57, 68, 75);
  }

  .secondary {
    background: $action-button-color;
    border: 2px solid $action-button-color;
  }

  button {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 22rem;
    height: 640px;
    padding: 0 3rem 0 3rem;
    align-content: center;
    border: 2px solid rgb(57, 68, 75);
    border-radius: 8px;
    /* Font properties */
    color: $main-font-color;
    font-size: 1.1rem;
    font-family: Barlow;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;

    transition: opacity 0.3s, box-shadow 0.3s;

    cursor: pointer;

    > span {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  button:focus {
    outline: none !important;
    box-shadow: 0 0 1px 1px $highlight-font-color;
  }

  button:disabled {
    cursor: default;
    opacity: 0.5;
  }

  .title {
    font-weight: 700;
  }
  .PlanSelectorPriceContainer{
    height: 170px;

    .DividerOrPhrase {
      min-height: 1rem;
      max-height: 1rem;

      .PlanDivider {
        border: none;
        height: 3px;
        width: 100%;
        border-bottom: 1px solid $main-font-color-muted;
      }
    }
  }
  &__children {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    &__child {
      padding: 1.3rem 0;

      &__low {
        padding: 1.3rem 0;
        min-height: 2rem;
        max-height: 2rem;
      }

      &__high {
        padding: 1.3rem 0;
        min-height: 4rem;
        max-height: 4rem;
      }
    }

    .Icon {
      margin-right: 1.3rem;
    }
  }

  &__active {
    button {
      outline: none !important;
      box-shadow: 0 0 1px 1px $highlight-font-color;
    }
  }

  &__big {
    button {
      padding: 2rem;
    }

    .MuiSvgIcon-root {
      width: 3rem;
      height: 8rem;
    }

    max-width: 240px;
  }
}
