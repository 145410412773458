@import '~shared-design/styles/variables';

.button.ButtonWithIcon,
.ButtonWithIcon {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;

  &__Icon {
    height: 24px;
    margin-right: 15px;
  }

  &__Label {
    line-height: 22px;
    margin-top: -2px;
  }

  &.small {
    padding: 8px 12px;

    .ButtonWithIcon__Icon {
      margin-right: 8px;
      font-size: 19px;
      height: 19px;

      .MuiSvgIcon-root {
        font-size: inherit;
      }
    }

    .ButtonWithIcon__Label {
      margin-top: 0
    }
  }
}
