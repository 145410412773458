.Notification {
  position: fixed;
  z-index: 9999;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  animation: fade-in 0.5s;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
