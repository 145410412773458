@import '~shared-design/styles/variables';
@import '~shared-design/components/atoms/TextButton/TextButton';

.text-button-nav-link {
  @extend .text-button;

  &.text-button-nav-link--selected {
    color: $secondary-yellow;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.15rem;
      width: 100%;
      height: 1px;
      background: $secondary-yellow;;
      color: $secondary-yellow;
      animation: appear 200ms linear;
      overflow: hidden;

      @keyframes appear {
        0% {
          left: 50%;
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }
  }
}
