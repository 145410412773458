@import '~shared-design/styles/variables';

.PricePerUnit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__main-price {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  &__unit {
    font-size: 1rem;
    color: $main-font-color-more-muted;
  }

  &__tax {
    font-size: 0.875rem;
    color: $main-font-color-extra-muted;
  }
}
