@import '~shared-design/styles/variables';

.ContentBlock {
  border-radius: 8px;
  padding: 1.33em;
  background-color: $canonized-gray-bg-medium;

  &--topHighlight {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: 6px solid $secondary-yellow;  
    border-width: 6px 0 0 0 ;
  }

  .MutedText {
    margin-bottom: 2em;
  }

  .ContentBlock {
    background-color: $canonized-gray-bg-dark;

    .ContentBlock {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  > .Title--h3:first-child {
    margin-bottom: 2.5rem;
  }

  &--condensed {
    > .Title--h3:first-child {
      margin-bottom: 0;
    }
  }

  .TextField,
  .TextArea,
  .ChipsArray,
  .StyledSelect,
  .NumberField,
  .StyledFieldWrapper {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--condensed {
    .TextField,
    .TextArea,
    .ChipsArray,
    .StyledSelect,
    .NumberField,
    .StyledFieldWrapper {
      margin-bottom: 0;
    }
  }


  .StyledSelect {
    margin-bottom: 2.3rem; // Hack to get styled select in line with other text fields until properly unified.
  }

  > *:last-child,
  > .TextField:last-child input {
    margin-bottom: 0;
  }

  & + .ContentBlock,
  & + .StyledTable {
    margin-top: 15px;
  }
}
