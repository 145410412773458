@import "~shared-design/styles/variables";

.DateRangePicker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__toolbar {
    background-color: $picker-background;
    padding: 0 24px 0 24px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Barlow;
    font-weight: 400;
  }

  &__toolbarSubtitle {
    font-size: 0.75rem;
    margin-top: 0.4rem;
    text-transform: uppercase;
  }

  &__toolbarDate {
    font-size: 1.33rem;
    line-height: 1.235;

    &--withoutSubtitle {
      margin-top: 0.75rem + 0.4rem + 0.1rem;
    }
  }

  &__duration {
    margin: 1rem 0;
  }
}