@import "~shared-design/styles/fonts";
@import "~shared-design/styles/variables";

html {
  font-size: 16px;

  @media only screen and (max-width: $screen-medium) {
    font-size: 14px;
  }
}

body {
  color: $primary-light;
  background-color: $canonized-gray-bg-primary;
}

.content {
  padding-bottom: 50px;
}