.Flex {
  position: relative;

  &__Content {
    --gap: 15px;
    
    &--gap-5 {
      --gap: 5px;
    }

    &--gap-10 {
      --gap: 10px;
    }

    &--gap-15 {
      --gap: 15px;
    }

    &--gap-30 {
      --gap: 30px;
    }

    display: flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    // TODO Evaluate if it is a good idea to directly alter the childrens CSS
    // or should we wrap them under FlexItem component or something.

    &--grow > * {
      position: relative;
      flex-grow: 1;
    }

    & > * {
      margin: var(--gap) 0 0 var(--gap);
      // TODO: Remove these rows after ContentBlock has been refactored to not inject margins to its children.
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.FlexItem {
  position: relative;
}
