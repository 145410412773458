@import '~shared-design/styles/variables';

$uncanonized-text-gray: rgb(169, 178, 183);

.label-text {
  font-size: 14px;
  font-family: $main-font;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: $uncanonized-text-gray;
}
