@import "~shared-design/styles/variables";

$uncanonized-gray-bg-extra-light: rgb(57, 68, 75);

.space-card {
  display: flex;
  flex-direction: column;

  background: $uncanonized-gray-bg-extra-light;

  .space-card__link {
    text-decoration: none;
    transition: 0.2s filter;
    color: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &:hover {
      filter: brightness(1.1);
    }
  }

  .space-card__image-wrapper {
    width: 100%;
    height: 180px;
    background-color: rgba(255,255,255,0.1);
  }

  .space-card__description-wrapper {
    padding: 0 20px;
    flex-grow: 1;

    > p {
      max-height: 6rem;
      overflow: hidden;
      display: flex;
      position: relative;

      /* Fade text on overflow */
      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(rgba(0,0,0,0),  $uncanonized-gray-bg-extra-light);
        top: 0rem;
        height: 6rem;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
      }
    }
  }

  .space-card__info-label-wrapper .icon-text {
    margin-right: 1.5em;
  }

  .space-card__cta-wrapper {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding: 20px;
    text-align: center;
  }
}
