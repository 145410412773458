@import '~shared-design/styles/variables';

.StyledButton {
  display: inline-flex;
  flex-direction: column;

  .primary {
    background: rgb(57, 68, 75);
  }

  .secondary {
    background: $action-button-color;
    border: 2px solid $action-button-color;
  }

  .highlight {
    background: $secondary-light-blue;
  }

  .danger {
    background: $danger-button-color;
    border: 2px solid $danger-button-color;
  }

  button {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 22rem;
    height: 47px;
    padding: 0 3rem 0 3rem;
    align-content: center;
    border: 2px solid rgb(57, 68, 75);
    border-radius: 8px;
    /* Font properties */
    color: $main-font-color;
    font-size: 1.1rem;
    font-family: Barlow;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;

    transition: opacity 0.3s, box-shadow 0.3s;

    cursor: pointer;

    > span {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  button:focus {
    outline: none !important;
    box-shadow: 0 0 1px 1px $highlight-font-color;
  }

  button:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &__children {
    display: inline-flex;
    align-items: center;
    text-align: left;

    .Icon {
      margin-right: 1.3rem;
    }
  }

  &__active {
    button {
      outline: none !important;
      box-shadow: 0 0 1px 1px $highlight-font-color;
    }
  }

  &__big {
    button {
      padding: 4rem;
    }

    .MuiSvgIcon-root {
      width: 3rem;
      height: 3rem;
    }

    max-width: 240px;
  }

  &__small {
    button {
      padding: 5px 18px;
      font-size: 14px;
      line-height: 22px;
      font-weight: normal;
      height: auto;
    }
  }
}
