@import '~shared-design/styles/variables';

.StyledCheckbox {
  display: flex;

  &--vertical-child {
    flex-direction: column;
  }

  &__input {
    &.MuiCheckbox-root {
      color: rgba(255, 255, 255, 0.2);
      padding: 9px 0;
      transition: 0.1s all;

      &.Mui-checked {
        color: $secondary-yellow;
      }
    }

    :hover {
      border-radius: 10px;
      background-color: $picker-background;
      box-shadow: 0 0 0 0.5rem $picker-background;
    }
  }

  .InputLabel {
    margin-left: 15px;
  }

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--vertical {
      flex-direction: column;

      .InputLabel {
        margin-left: 0;
      }
    }

    .Icon {
      margin-left: 30px;
    }
  }
}
