@import "~shared-design/styles/variables";

.Divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;


    &::before,
    &::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid $canonized-gray-bg-light;
    }

  &__text {
    margin: 0 1rem;
    color: $main-font-color-muted;
  }
}