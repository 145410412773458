@import "~shared-design/styles/variables";

.ReservationFilter {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 2rem 2rem 0;
}

.PersonCountSelector {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;

  @media only screen and (max-width: $screen-medium) {
    margin-right: 1rem;
  }

  &__icon {
    color: $secondary-yellow;
    width: 22px;
    height: 22px;
    align-self: center;
    margin-right: 0.5rem;
  }
}

.DateTimePicker {
  color: #fff;

  input {
    color: #fff;
  }
}

.DatePickers {
  .DatePicker {
    margin-right: 1rem;
  }

  @media only screen and (max-width: $screen-medium) {
    flex-basis: 100%;
    margin-left: 0;
    justify-content: space-between;
  }
}
