@import '~shared-design/styles/variables';

.MutedText {
  color: $main-font-color-more-muted;
  font-size: 14px;
  letter-spacing: 0;
}

.ExtraMutedText {
  color: $main-font-color-extra-muted;
  text-transform: none;
  letter-spacing: normal;
  font-size: 0.9rem;
  padding-top: 0.5rem;
  min-height: 1rem;
  max-height: 2rem;
  font-weight: 500;
}
