@import '~shared-design/styles/variables';

.text-button {
  color: $primary-light;
  font-family: $main-font;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  cursor: pointer;
  border: none;
  background: none;
  transition: color 0.2s ease-in;
  padding: 0;

  &:hover {
    color: $secondary-yellow;
  }

  &:focus {
    outline: none;
  }

  &.danger {
    color: $secondary-dark-red;

    &:hover {
      color: $secondary-red;
    }
  }

  &[disabled] {
    color: $main-font-color-extra-muted;
    cursor: default;

    &:hover {
      color: $main-font-color-extra-muted;
    }
  }
}
