@import "~shared-design/styles/variables";

.LoginPage {
  &__wrapper {
    width: 100%;
  }

  &__container {
    display: flex;
    padding: 0 20px;
    max-width: 900px;
    margin: 0 auto;
    justify-content: center;

    @media (min-width: $screen-small) {
      & {
        padding: 0 45px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 22rem;
    min-height: 300px;
  }

  a {
    color: $secondary-yellow;
    font-weight: 600;
  }
}
