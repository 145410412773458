@import '~shared-design/styles/variables';

.TextField {
  $p: &;

  &__wrapper {
    display: flex;
    flex-direction: column;

    max-width: 22rem;

    font-family: Barlow;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgb(242, 240, 240);

    transition: color 0.2s;
  }

  &__input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: $canonized-gray-bg-light;
    border: 2px solid rgb(57, 68, 75);
    border-radius: 8px;
    height: 47px;
    border: none;
    transition: box-shadow 0.3s;

    padding: 1rem;
    margin-top: 16px;

    font-family: Barlow;
    font-weight: 500;
    font-size: 1.1rem;
    color: $main-font-color;
  }

  .Flex {
    margin-top: 16px;
  }

  &__input:disabled {
    border: 1px solid $canonized-gray-bg-light;
    background-color: transparent;
    color: $main-font-color-extra-muted;
  }

  &__input:focus {
    border: none;
    outline: none !important;
    box-shadow: 0 0 0 2px #565656;
  }

  &__input--error {
    border: none;
    outline: none !important;
    margin-bottom: 0;
    box-shadow: 0 0 0 2px $secondary-red  !important;
  }

  @mixin underInput {
    position: relative;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    min-height: 1rem;
    max-height: 2rem;
    font-weight: 500;
  }

  &__error {
    @include underInput;
    color: $secondary-red;
  }

  &__helper {
    @include underInput;
    color: $main-font-color-extra-muted;
  }

  &__wrapper:focus-within {
    .InputLabel {
      color: $highlight-font-color;
    }
  }
}