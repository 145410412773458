@import '~shared-design/styles/variables';

.ReservationsList {
  display: flex;
  flex-direction: column;

  max-width: 800px;
  margin: auto;

  &__no-reservations {
    display: flex;
    margin: auto;
    justify-content: center;
  }
}

.ReservationRow {
  display: flex;
  flex-wrap: wrap;

  //border-bottom: 1px solid #39444b;

  padding: 2rem 1rem 2rem 1rem;

  @media only screen and (max-width: $screen-medium) {
    flex-direction: column;
    margin: auto;
  }

  &__content {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: $screen-medium) {
      flex-direction: column;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2rem;

    @media only screen and (max-width: $screen-medium) {
      margin-left: 0;
    }
  }

  &__name {
    color: $secondary-yellow;
    font-family: $main-font;
    font-weight: 600;
    letter-spacing: -0.46px;
    margin-bottom: 0.5rem;
    font-size: 1rem;

    @media only screen and (max-width: $screen-medium) {
      margin-top: 0.5rem;
    }
  }

  // TODO: Pass styles to the button component instead
  &__cancel {
    display: flex;
    flex-shrink: 0;
    align-self: center;
    margin-left: auto;

    @media only screen and (max-width: $screen-medium) {
      margin-top: 2rem;
      flex-basis: 100%;
      margin-left: 0;
      width: 100%;
    }

    .StyledButton {
      flex: 1;
      flex-grow: 1;
    }

    button {
      padding: 0.5rem 1rem;
      margin: 0;
      line-height: 1.3;
      height: auto;
      width: 100%;
      min-height: 36px;
      font-size: 0.9rem;
    }
  }

  &__btn-wrapper {
    display: flex;
    align-self: center;
  }
}

.ReservationDates {
  display: flex;

  :nth-child(1) {
    margin-right: 2rem;
  }
}

.RoomImage {
  img {
    max-width: 120px;
    max-height: 90px;

    @media only screen and (max-width: $screen-medium) {
      max-width: 240px;
      max-height: 180px;
    }
  }
}
