@import './colors';

$main-bg-color: #1a1e21;
$main-font-color: #f2f0f0;
$main-font-color-muted: rgb(216, 216, 216);
$main-font-color-more-muted: #cecece;
$main-font-color-extra-muted: #848688;
$main-font-color-super-muted: rgb(58, 62, 65);
$main-button-color: #3b444a;
$main-button-hover: #878e93;
$main-button-selected: #bdc1c4;
$main-button-disabled: #2d3337;
$danger-button-color: #e23b11;
$danger-button-hover: #f03e11;
$action-button-color: #4b87f7;
$action-button-hover: #5b97ff;
$action-button-disabled: #8590a1;
$timeslot-hover: #53585e;
$background-invalid: #f8b6b6;
$room-reservation-bg-cash: #eeb954;
$room-reservation-bg-invoice: #abb2b6;
$room-reservation-bg-invoice-hover: #bdc1c4;
$room-reservation-hover: #f9d188;
$room-reservation-disabled: #b3a68d;
$room-reservation-border: rgb(126, 107, 71);
$timeslot-border: #5f666b;
$room-name-bg: #3b444a48;
$top-nav-selected: #eeb954;
$top-nav-hover: #f9d188;

$picker-background: #3b444a;
$picker-background-hover: $secondary-yellow;

$indicator-green: #63d295;
$indicator-yellow: #f7b83a;
$indicator-red: #ea6b26;

$capacity-usage-low: $indicator-green;
$capacity-usage-medium: $indicator-yellow;
$capacity-usage-high: $indicator-red;
$capacity-usage-font-color: #22282c;
$capacity-usage-background: $canonized-gray-bg-lighter;

$highlight-font-color: $secondary-yellow;
$highlight-font-color-muted: rgba($highlight-font-color, 0.8);
$highlight-font-color-hover: lighten($highlight-font-color, 15%);

// Documentation purposes
$raster-color-1: #2c343b;
$raster-color-2: #39444b;

// Matching app
$app-whitesmoke-gray-markdown: rgba(239, 239, 239, 0.85);

$main-font: 'Barlow';

$trend-up: $indicator-green;
$trend-down: $indicator-red;
$trend-small: #4b87f7;

:export {
  mainBgColor: $main-bg-color;
  mainFontColor: $main-font-color;
  mainFontColorMuted: $main-font-color-muted;
  mainFontColorMoreMuted: $main-font-color-more-muted;
  mainFontColorExtraMuted: $main-font-color-extra-muted;
  mainFontColorSuperMuted: $main-font-color-super-muted;
  mainButtonColor: $main-button-color;
  mainButtonHover: $main-button-hover;
  mainButtonSelected: $main-button-selected;
  mainButtonDisabled: $main-button-disabled;
  dangerButtonColor: $danger-button-color;
  dangerButtonHover: $danger-button-hover;
  actionButtonColor: $action-button-color;
  actionButtonHover: $action-button-hover;
  actionButtonDisabled: $action-button-disabled;
  timeslotHover: $timeslot-hover;
  backgroundInvalid: $background-invalid;
  roomReservationBgCash: $room-reservation-bg-cash;
  roomReservationBgInvoice: $room-reservation-bg-invoice;
  roomReservationBgInvoiceHover: $room-reservation-bg-invoice-hover;
  roomReservationHover: $room-reservation-hover;
  roomReservationDisabled: $room-reservation-disabled;
  roomReservationBorder: $room-reservation-border;
  timeslotBorder: $timeslot-border;
  roomNameBg: $room-name-bg;
  topNavSelected: $top-nav-selected;
  topNavHover: $top-nav-hover;
  pickerBackground: $picker-background;
  pickerBackgroundHover: $picker-background-hover;
  capacityUsageLow: $capacity-usage-low;
  capacityUsageMedium: $capacity-usage-medium;
  capacityUsageHigh: $capacity-usage-high;
  capacityUsageFontColor: $capacity-usage-font-color;
  capacityUsageBackground: $capacity-usage-background;
  highlightFontColor: $highlight-font-color;
  rasterColor1: $raster-color-1;
  rasterColor2: $raster-color-2;
  appWhitesmokeGrayMarkdown: $app-whitesmoke-gray-markdown;
  trendUp: $trend-up;
  trendDown: $trend-down;
  trendSmall: $trend-small;
}
