@import "~shared-design/styles/variables";

.Tooltip {
    display: inline-flex;
    align-items: center;

    &__Children {
        display: inline-flex;
    }

    &__Children + .Icon {
        margin-left: 10px;
    }

    .Icon.info {
        color: $main-font-color-extra-muted;

        .MuiSvgIcon-root {
            vertical-align: middle;
        }
    }
}