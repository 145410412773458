@import '~shared-design/styles/variables';

.CopyToClipboardButton {
  & .Icon {
    transition: color 0.2s;

    .SecondaryTextButton {
      padding: 0
    }

    &.check {
      color: $secondary-green;
    }
  }

  &--Inline {
    display: inline-block;
    vertical-align: middle;
  }

  &--Small {
    margin: 0 0.5rem;

    .Icon {
      font-size: 1rem;
    }
  }
}
