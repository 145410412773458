@import '~shared-design/styles/variables';

.Chip {
  height: 32px;
  background: transparent;
  border: 2px solid rgb(38, 47, 54);
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: 4px;

  &__label {
    font-size: 0.8rem;
    color: $primary-light;
    font-family: $main-font;
    font-weight: normal;
    letter-spacing: 0;
    padding-left: 12px;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__delete {
    margin: 0 5px 0 -6px !important;
  }
}