@import "~shared-design/styles/variables";

.RadioButtonGroup {
  .MuiRadio-root {
    color: #20272d;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $highlight-font-color;
  }


  .MuiTypography-body1 {
    font-family: 'Barlow';
    font-size: 0.875;
    font-weight: 500;
    margin-left: 2rem;
  }
}
