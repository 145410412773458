@import '~shared-design/styles/variables';

.InputLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Barlow;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #f2f0f0;

  &.InputLabel--lower {
    font-size: 14px;
    font-weight: 500;
    text-transform: initial;
    letter-spacing: initial;
  }

  .required {
    color: $highlight-font-color;
  }

  &--error {
    color: $secondary-red;
  }

  .Tooltip {
    margin-bottom: -5px;
    margin-top: -5px;
  }
}
