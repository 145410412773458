@import "~shared-design/styles/variables";

.ArrowBackHeader {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover &__icon {
    transform: scale(1.1) translate(-30%, 0);
  }

  &__icon.MuiSvgIcon-root {
    transition: all 250ms ease-in-out;
    margin: 1px 16px 0 0;
    color: $secondary-yellow;
    width: 30px;
    height: auto;
  }
}
