@import "~shared-design/styles/variables";

.SecondaryText {
  $fontSize: 0.8em;

  display: inline-flex;
  align-items: center;
  font-size: $fontSize;
  letter-spacing: 0.37px;
  font-family: "Barlow";
  font-weight: 500;
  transition: color 0.2s ease;

  .Icon + .SecondaryText__Text {
    margin-left: $fontSize;
  }

  &.yellow {
    color: $secondary-yellow;
  }
  &.blue {
    color: $secondary-blue;
  }
}