@import '~shared-design/styles/variables';

.InformationBlockButton {
  display: inline-flex;
  flex-direction: column;
  padding: 32px 32px 32px 0;
  height: 300px;
  width: 240px;

  .primary {
    background:$main-bg-color;
  }

  .secondary {
    background: $main-bg-color;
    border: 2px solid $action-button-color;
  }

  &__content {
    display: inline-flex;
    flex-direction: column;
    padding: 0 30px;
    justify-content: space-between;
    position:relative;
    height: 100%;
    width: 100%;
  }
  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
  button {
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 22rem;
    align-content: center;
    border: 2px solid rgb(57, 68, 75);
    border-radius: 8px;
    /* Font properties */
    color: $main-font-color;
    font-size: 1.1rem;
    font-family: Barlow;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;

    transition: opacity 0.3s, box-shadow 0.3s;

    cursor: pointer;

    > span {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  button:focus {
    outline: none !important;
    box-shadow: 0 0 1px 1px $highlight-font-color;
  }

  button:disabled {
    cursor: default;
    opacity: 0.5;
  }

  .title {
    font-weight: 700;
  }

  &__icon {
    padding-top: 49px;

  }

  &__active {
    button {
      outline: none !important;
      box-shadow: 0 0 1px 1px $highlight-font-color;
    }
  }

  &__text {
    padding-top: 30px;

  }

  &__cta_text {
    color: $highlight-font-color;
    padding-bottom: 30px;
  }
}
