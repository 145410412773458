@import "~shared-design/styles/variables";

.TimeRange {
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 12rem;

  margin-left: 0.5rem;

  &__icon {
    color: $secondary-yellow;
    margin-right: 0.5rem;
  }

  &__separator {
    display: flex;
    margin: 0 0.2rem 0 0.2rem;
    justify-content: center;
    font-weight: 600;
  }
}

.TimeRange {
  .StyledSelect {
    &__control {
      height: 2.75rem;
    }
  }
}
