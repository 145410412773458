@import "~shared-design/styles/variables";

.NumberField {
  color: #fff;
  font-family: "Barlow";

  .NumberField__label {
    font-family: Barlow;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgb(242, 240, 240);
    margin-bottom: 1.3rem;

    > .required {
      color: $secondary-yellow;
    }
  }

  &__btn {
    height: 2.75rem;
    width: 2.75rem;
    background: $canonized-gray-bg-light;
    color: #fff;
    font-weight: 500;
    border: none;
    margin-right: 0.2rem;
    transition: 1s all;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .Icon {
      font-size: 1.1rem;
    }

    &:hover {
      cursor: pointer;
      border: none;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:disabled:hover {
      cursor: default;
    }

    &:focus {
      border: none;
      outline: none !important;
    }

    &:not(disabled):active,
    &:not(disabled):focus {
      transition: 0s;
      box-shadow: inset 0 0 0 2px #565656;
    }
  }


  &__label {
    font-size: 0.8rem;
  }

  &__error {
    position: relative;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    color: $secondary-red;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    min-height: 1rem;
    max-height: 2rem;
    font-weight: 500;
  }

  &__control {
    display: inline-flex;
    flex-direction: row;
    border-radius: 6px;
    overflow: hidden;
    transition: opacity 0.2s;

    input {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .NumberField--noButtons & input {
      border-radius: 6px;
    }

    &__symbol {
      opacity: 0.5;
      height: 2.75rem;
      padding: 0 0.5rem;
      background: $canonized-gray-bg-light;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 500;
      border: none;
      margin-right: 0.2rem;
      display: inline-flex;
      align-items: center;
    }

    &--error {
      box-shadow: 0 0 0 2px $secondary-red !important;
      border: none !important;
      outline: none !important;
    }

    :last-child {
      margin-right: 0;
    }
  }

  &--unset {
    .NumberField__control {
      opacity: 0.4;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.IntegerInputControl {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:disabled {
    border: 1px solid $canonized-gray-bg-light;
    background-color: transparent;
    color: $main-font-color-extra-muted;
  }

  &:focus {
    border: none;
    outline: none !important;
    box-shadow: inset 0 0 0 2px #565656;
  }

  font-weight: 500;
  font-size: 1.1rem;
  color: $main-font-color;

  background: $canonized-gray-bg-light;
  max-width: 6rem;
  height: 2.75rem;
  border: none;

  padding: 0 1rem 0 1rem;

  font-family: "Barlow";
  font-weight: 500;
  letter-spacing: 0px;

  margin-right: 0.2rem;
  margin-left: 0;

  transition: box-shadow 0.3s;
}

.NumberFieldLabelPadding {
  padding-top: 32.5px;
  align-self: center;
}