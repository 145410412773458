@import "~shared-design/styles/variables";


.SecondaryTextButton {
  font-size: 14px;
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;

  &[disabled] {
    .SecondaryText.yellow {
      color: rgb(126, 107, 71);
    }

   &:hover {
     cursor: auto;

     .SecondaryText.yellow {
       color: rgb(126, 107, 71);
     }
   }
  }

  &:hover {
    &.yellow .SecondaryText {
      color: $highlight-font-color-hover;
    }
    &.blue .SecondaryText {
       color: $secondary-light-blue;
     }
  }
}
