@import '~shared-design/styles/variables';

// Safari needs an extra div before `display: grid` for grid height to work properly
.Message {
  &__grid {
    display: grid;
    grid-template-columns: 25px auto;
    grid-template-areas:
      'status title'
      'content content';
    border-radius: 8px;
    padding: 25px;
    background-color: $canonized-gray-bg-dark;

    &--short {
      .Message__grid--content {
        margin-top: 0;
      }

      grid-template-areas: 'status content';
    }

    &--title {
      grid-area: title;
      color: $primary-light;
      text-transform: uppercase;
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      line-height: 15px;
      letter-spacing: 1.78px;
      opacity: 0.8;
    }

    &--content {
      grid-area: content;
      margin: 1rem 1rem 0 0;
      color: rgb(216, 216, 216);
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 21px;

      &.pre {
        white-space: pre-line;
      }
    }

    &--title + &--content {
      margin-left: 1.6rem;
    }

    & .Dot {
      grid-area: status;
      align-self: center;
    }
  }
}
