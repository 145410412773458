@import '~shared-design/styles/variables';

.EditableList {
  & &--controls {
    max-width: 36rem;

    &-container {
      display: flex;
      flex-wrap: wrap;
    }

    & .TextField {
      margin-bottom: 0;
      width: 22rem;
      flex-grow: 1;
    }

    & .StyledButton {
      margin-top: 16px;
      flex-grow: 1;
    }
  }

  &--checklist {
    display: inline-flex;
    flex-direction: column;
    max-width: 36rem;
    margin-top: 1.3rem;

    &-item {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin: 0.5rem;
      border-bottom: 1px solid $canonized-gray-bg-light;
    }

    &-removeIcon {
      padding-left: 1rem;
      color: $secondary-yellow;
      margin-left: auto;
    }
  }
}