.SpacentLogo {
  &, & &__Link {
    display: inline-flex;
    align-items: center;
    color: rgb(242, 240, 240);
  }

  svg {
    width: 100%;
  }
}
