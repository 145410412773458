@import '~shared-design/styles/variables';

.StyledSelect {
  min-width: 6rem;
  color: $primary-light;

  font-family: 'Barlow';
  font-weight: 500;

  max-width: 22rem;

  .InputLabel {
    margin-bottom: 1.3rem;
  }

  &:hover {
    .StyledSelect__dropdown-indicator {
      color: $secondary-yellow !important;
    }
  }

  &__hide-indicator {
    .StyledSelect__dropdown-indicator {
      display: none;
    }
  }

  & &__control {
    background-color: $canonized-gray-bg-light !important;
    border: none !important;
    border-radius: 8px !important;
    min-height: 47px;
    padding: 0 5px;

    &--is-focused {
      box-shadow: inset 0 0 0 2px #565656 !important;
      border: none !important;
      outline: none !important;
    }

    .StyledSelect__value-container {
      padding: 10px 0;
      margin-bottom: 4px;
    }

    .StyledSelect__multi-value {
      $chipBgColor: #a9b2b7;
      /**
       * Based on the <Tag /> component with some tweaks to legibility.
       */
      display: flex;

      border-radius: 15px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      user-select: none;

      background: $chipBgColor;
      padding: 3px 7px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      &__label {
        color: $capacity-usage-font-color;
      }

      &__remove {
        margin-left: 5px;
        color: $capacity-usage-font-color;
        padding: 0 2px;
        cursor: pointer;

        &:hover {
          background-color: $capacity-usage-font-color;
          color: $chipBgColor;
        }
      }
    }

    &--is-disabled {
      .StyledSelect__multi-value__remove {
        display: none;
      }
    }
  }

  &__value-container {
    padding-left: 0.8rem !important;
  }

  &__single-value {
    color: $primary-light !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__menu {
    background-color: $canonized-gray-bg-light !important;
    z-index: 30 !important;
  }

  &__option {
    color: $primary-light !important;

    &--is-selected {
      opacity: 1;
      background: none !important;
      color: $secondary-yellow !important;
    }

    &--is-focused {
      background: rgba(255, 255, 255, 0.1) !important;
      opacity: 1;
    }
  }

  &--is-disabled {
    opacity: 0.5;
  }

  &__dropdown-indicator {
    color: $primary-light !important;
  }

  &__clear-indicator {
    color: $primary-light !important;

    :hover {
      color: $secondary-yellow !important;
      transition: all 0.3s;
    }
  }

  &--text-only {
    @extend .StyledSelect;

    min-width: 0rem !important;

    font-weight: 600;

    &__single-value {
      position: relative !important;
      top: unset !important;
      -webkit-transform: unset !important;
      -ms-transform: unset !important;
      transform: unset !important;

      color: $primary-light !important;
      max-width: 100% !important;

      overflow: unset !important;
      text-overflow: unset;

      transition: color 0.5s;

      &:hover {
        color: $secondary-yellow !important;
        cursor: pointer;
      }
    }

    &__value-container {
      overflow: unset !important;
      flex-wrap: nowrap !important;
      padding: 0 !important;
    }

    &__control {
      background: none !important;
      box-shadow: none !important;
      border: none !important;

      &--is-focused {
        box-shadow: none !important;
        color: $secondary-yellow !important;
      }

      &--menu-is-open {
        border: none !important;
        color: $secondary-yellow !important;

        .StyledSelect--text-only__single-value {
          color: $secondary-yellow !important;
        }
      }
    }

    &__indicator-separator {
      display: none !important;
    }

    &__indicators {
      display: none !important;
    }

    &__menu {
      width: 6rem !important;
      background-color: $canonized-gray-bg-light !important;
    }

    &__option {
      color: $primary-light !important;

      &--is-selected {
        opacity: 1;
        background: none !important;
        color: $secondary-yellow !important;
      }

      &--is-focused {
        background: rgba(255, 255, 255, 0.1) !important;
        opacity: 1;
      }
    }

    &__placeholder {
      position: relative !important;
      top: unset !important;
      -webkit-transform: unset !important;
      -ms-transform: unset !important;
      transform: unset !important;
    }
  }

  &__input {
    color: $primary-light;
  }

  &--Error {
    color: $secondary-dark-red;

    .StyledSelect__control {
      border: 2px solid $secondary-dark-red !important;
    }
  }
}
