@import '~shared-design/styles/variables';

.LocaleSelector {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  & .StyledSelect {
    min-width: 8rem;
  }
}
