.AuthMethods {
  display: flex;
  flex-direction: column;

  .MsftAuth {
    display: contents;

    img {
      height: 25px;
      min-width: 25px;
      margin-right: 0.9rem;
    }
  }

  .GoogleAuth {
    display: contents;

    img {
      height: 18px;
      min-width: 18px;
      margin-right: 0.9rem;
    }
  }

  .EmailAuth {
    display: flex;
    flex-direction: column;
  }
}

.separator {
  border: none;
  border-top: 1px solid rgba(151, 151, 151, 0.25);
  max-width: 22rem;
  height: 1px;
  margin-bottom: 2.6rem;
}
