@import '~shared-design/styles/variables';

.TextArea {
  margin-bottom: 1.88rem;

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    transition: color 0.2s;
  }

  &__wrapper:focus-within {
    color: $highlight-font-color;
  }

  &__label {
    display: flex;
    flex-direction: row;

    .required {
      color: $highlight-font-color;
    }
  }

  &__input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: $canonized-gray-bg-light;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    border: none;
    transition: box-shadow 0.3s;

    padding: 1rem;
    margin-top: 1.3rem;

    font-family: Barlow;
    font-weight: 500;
    font-size: 1.1rem;
    color: $main-font-color;
  }

  &__input:disabled {
    opacity: 0.5;
  }

  &__input:focus {
    border: none;
    outline: none !important;
    box-shadow: 0 0 0 1px #565656;
  }

  &__input--error {
    border: none;
    outline: none !important;
    margin-bottom: 0;
    box-shadow: 0 0 0 2px $secondary-red !important;
  }

  &__error {
    position: relative;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    color: $secondary-red;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    min-height: 1rem;
    max-height: 2rem;
    font-weight: 500;
  }
}
