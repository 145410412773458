@import "~shared-design/styles/variables";

.Title {
  color: $main-font-color;
  font-family: $main-font;
  font-weight: 600;
  letter-spacing: 0.48px;
  margin: 1em 0;

  &.secondary {
    color: $secondary-yellow
  }

  &--h1,
  &--h2 {
    font-size: 24px;
    line-height: 22px;
  }

  &--h3 {
    font-size: 16px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 1.78px
  }

  &--h4,
  &--h5,
  &--h6 {
    font-size: 11px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1.83px;
  }

  &--bold {
    font-size: 32px;
    font-weight: 700;
  }

  &--muted {
    color: $main-font-color-muted;
    font-weight: 500;
  }
}

