@import '~shared-design/styles/variables';

.GuestCheckInSuccess {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    width: 400px;
    background-color: $canonized-gray-bg-dark;
    padding: 20px;
  }

  &__header {
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__desc {
    margin-top: 30px;
  }
}
